export default function onKeybind(keybind: string, func: (event: KeyboardEvent) => void) {
	document.addEventListener("keydown", e => {
		let modifiers = keybind.split("+");
		let key = modifiers.pop();

		if (key != e.key) return;

		for (let modifier of modifiers) {
			if (!(e as any)[`${modifier}Key`]) {
				return;
			}
		}

		e.preventDefault();
		return func(e);
	});
}
