import onKeybind from "./lib/onkeybind"

//
// ==UserScript==
// @name Show Archived Copies
// @description Shows archived copies of this page on the Wayback Machine when alt+shift+a is pressed.
// @namespace https://userscripts.kevincox.ca
// @version  1
// @grant    none
// ==/UserScript==

onKeybind("alt+A", _event => {
	document.location.href = `https://web.archive.org/web/${document.location.href}`;
});
